@use "styles/shared" as *;

.DashboardCalendar {
  border-radius: 3px;
  background-color: $white;
  @media screen and (min-width: 991px) {
    height: calc(100vh - 149px);
  }
  :global {
    .dboard-cal__header {
      border-bottom: 1px solid $light-grey;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      h2 {
        margin-bottom: 0;
      }
    }
    .dboard-cal__content {
      max-height: 400px;
      overflow-y: auto;

      @media screen and (min-width: 480px) {
        padding: 12px 16px;
        min-height: calc(100vh - 334px);
        max-height: calc(100vh - 334px);
      }
      @include device(mobile) {
        padding: 12px 16px;
        max-height: none;
        padding-top: 0;
      }
    }
    .block {
      &.no-events {
        margin-bottom: 26px;
        h3 {
          color: rgba(0, 0, 0, 0.38);
        }
      }
      .block__header {
        padding: 12px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        @include device(mobile) {
          margin: 0 -16px;
        }
      }
      h3 {
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 0;
      }
      .block__details {
        padding: 12px 16px 6px;
        @include device(mobile) {
          padding: 12px 0px 6px;
        }
      }
    }
    .widget {
      border-radius: 4px;
      background: #eeeeee;
      padding: 6px 8px;
      margin-bottom: 8px;
      min-height: 60px;
      cursor: pointer;
      .widget__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
        gap: 20px;
        h3 {
          margin-bottom: 0;
        }
        &--light {
          color: rgba(0, 0, 0, 0.38);
          margin-bottom: 0;
          p {
            color: rgba(0, 0, 0, 0.38);
            margin-bottom: 0;
            line-height: 20px;
          }
        }
        &--dark {
          h3 {
            line-height: 20px;
            font-weight: 600;
            text-transform: capitalize;
          }
          .widget__details {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
        .widget__details {
          align-self: flex-end;
          flex-basis: calc(100% - 69px);
          max-width: calc(100% - 69px);
          svg {
            max-width: 16px;
            max-height: 16px;
          }
        }
        .widget__time {
          text-align: right;
          align-self: flex-end;
          flex-basis: 65px;
          max-width: 65px;
        }
        .widget__thumb {
          display: flex;
          align-items: center;
        }
      }
    }
    .dashboard-select {
      @include device(mobile) {
        min-width: 160px;
      }
    }
  }
}

.MiniCalendarAddEvent {
  border-radius: 8px;
  @include device(mobile) {
    padding: 20px 0px !important;
  }
  :global {
    .event-modal {
      padding: 0px 0px 16px;
      .add-edit-event-errors {
        display: flex;
        flex-direction: column;
        span {
          margin-bottom: 5px;
          color: red;
        }
      }
    }
    .header {
      @include device(mobile) {
        margin-bottom: 0 !important;
        padding: 0 16px;
      }
      h2 {
        font-size: 17px !important;
        line-height: 16px;
      }
    }
    .event-modal__input {
      margin-top: 20px;
      label {
        display: none;
      }
      input {
        color: $text-black;
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        padding: 14px 12px;
        border: 1px solid $light-grey;
        border-radius: 3px;
        width: 100%;
        &:active,
        &:focus {
          box-shadow: none;
        }
      }
    }
    .event-header {
      @include device(mobile) {
        padding: 0px 25px;
        padding-bottom: 20px !important;
        min-width: auto !important;
      }
      .header-left {
        @include device(mobile) {
          min-width: auto !important;
        }
        .event-title {
          @include device(mobile) {
            font-size: 20px;
            line-height: 24px;
            color: #373c43;
          }
        }
      }
    }
    .event-body {
      .calendar-color {
        @include device(mobile) {
          margin: 0 16px 0 0 !important;
        }
      }
      .event-row {
        @include device(mobile) {
          margin: 0 25px;
        }
        .event-text {
          span {
            @include device(mobile) {
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }
        .event-icon {
          @include device(mobile) {
            display: flex;
            align-items: center;
            margin-right: 12px !important;
          }
        }
      }
    }
    .footer {
      @include device(mobile) {
        padding: 16px 25px 0 !important;
      }

      span {
        @include device(mobile) {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
    .event-modal__date {
      margin-bottom: 20px;
    }
    .event-modal__time {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 21px;
      .time-start {
        flex-basis: calc(50% - 12px);
        max-width: calc(50% - 12px);
      }
      .time-end {
        flex-basis: calc(50% - 12px);
        max-width: calc(50% - 12px);
      }
    }
    .event-modal__btn {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      .btn {
        padding: 10px 16px;
        font-size: 16px;
        max-height: 40px;
        &--white {
          color: $black;
          border: none;
          background-color: #eeeeee;
          border-radius: 3px;
        }
      }
    }
    .close {
      display: none;
    }
    .add_edit_event_form {
      @include device(mobile) {
        padding: 0 16px;
      }
      h2 {
        @include device(mobile) {
          font-weight: 700;
          font-size: 17px;
          line-height: 16px;
          color: #373c43;
          font-family: $primary-font;
        }
      }
      .event_form_user {
        height: 48px;
        margin: 20px 0;
        .option {
          max-width: 160px;
        }
      }
      .event_form_title,
      .event-modal__date {
        height: 48px;
      }
      .event-modal__btn {
        .btn--white {
          @include device(mobile) {
            color: #373c43;
          }
        }
      }
      .event-modal {
        @include device(mobile) {
          padding-bottom: 0;
        }
        .equipments {
          .equipments__title {
            padding: 14px 24px 16px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          }
          h3 {
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 0;
          }
          .equipments__list {
            padding: 12px 24px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            max-height: 207px;
            overflow-y: auto;
            li {
              display: flex;
              align-items: center;
              margin-bottom: 12px;
            }
            label {
              color: $text-black;
              margin-left: 8px;
              font-size: 16px;
              line-height: 20px;
            }
            .checkbox {
              width: 18px;
              height: 18px;
              margin: 3px;
              padding: 3px;
              display: inline-block;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
