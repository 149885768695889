@use "styles/shared" as *;

.MiniViewEvent {
  :global {
    color: $black;
    .event-header {
      display: flex;
      justify-content: space-between;
      min-width: 303px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      .header-left {
        min-width: 250px;
   
        .event-title {
          font-weight: 400;
          font-size: 20px;
          margin-left:10px;
        }
        svg {
          // width: 18px;
          // height: 16px;
        }
      }
    }
    .event-body {
      // padding: 0px 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      max-height: 663px;
      overflow-y: auto;
      .event-row {
        padding: 16px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
        &:last-child {
          border-bottom: none;
        }
        .event-text {
          color: rgba(0, 0, 0, 0.6);
          span {
            text-transform: capitalize;
          }
          ul {
            margin: 8px 0 0;
            li {
              line-height: 20px;
              color: rgba(0, 0, 0, 0.6);
            }
          }
          p {
            font-size: 14px;
            line-height: 18px;
            margin: 8px 0 0;
            color: rgba(0, 0, 0, 0.6);
          }
          .equipment-list {
            margin-bottom: 8px;
          }
          a {
            color: #207ba5;
          }
          color: $text-grey2;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          .dot-separator {
            margin: 0 7px;
          }
        }
        .event-icon {
          align-self: flex-start;
          width: 20px;
          height: 18px;
          margin-right: 12px;
          text-align: center;
        }
      }
      .calendar-color {
        width: 12px;
        height: 12px;
        margin: 6px;
        border-radius: 50%;
        margin-right: 14px;
      }
    }
    .footer {
      padding: 20px 0px;
      display: flex;
      gap: 20px;
      button {
        border: none;
        background: $white;
      }
      span {
        font-size: 14px;
        line-height: 20px;
        margin-left: 6px;
        display: inline-block;
      }
      svg {
        height: 20px;
        width: 20px;
      }
    }
    .cta-action {
      padding: 12px;
      .btn {
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        .cta-icon {
          svg {
            width: 14px;
            height: 20px;
            path {
              fill: $white;
            }
          }
        }
      }
    }
    p {
      color: $black;
    }
  }
}

.MiniViewEvent__default {
  :global {
  }
}
