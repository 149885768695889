@use "styles/shared" as *;

.WeekDatePicker {
  max-width: 100%;
  padding: 10px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  :global {
    .week-picker__header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 17px;
    }
    .row>*{
      padding-left: 0;
      padding-right: 0;
    }
    .week-picker__days{
      text-align: center;
      margin-bottom: 2px;
      flex-wrap: nowrap;
      .day{
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .week-picker__dates{
      .date{
        text-align: center;
        cursor: pointer;
        span{
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          font-size: 18px;
          line-height: 24px;
        }
        &--current{
          span{
            background: #289FC1;
            border-radius: 50%;
            color: $white;
            font-weight: 700;
          }
        }
        &--selected{
          span{
            background: #d2e3fc;
            border-radius: 50%;
            color: #185abc;
            font-weight: 700;
          }
        }
      }
    }
    .week-picker__month{
      display: flex;
      align-items: center;
      h2{
        font-weight: 600;
        margin-bottom: 0;
      }
      .chevron-down{
        width: 14px;
        margin-left: 4px;
      }
    }
    .week-picker__nav{
      display: flex;
      align-items: center;
    }
    .week-picker__add-events{
      position: relative;
      display: none;
      .add{
        width: 24px;
        height: 24px;
        padding: 5px;
        display: block;
        cursor: pointer;
        margin-right: 6px;
        @media screen and (min-width: 360px){
          margin-right: 24px;
        }
        path{
          fill: rgba(0, 0, 0, 0.6);
        }
      }
    }
    .week-picker__menu{
      min-width: 160px;
      max-width: 160px;
      box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      position: absolute;
      display: none;
      top: 36px;
      right: -48px;
      background-color: $white;
      &::before{
        content: '';
        position: absolute;
        left: 50%;
        top: -10px;
        width: 20px;
        height: 20px;
        transform: rotate(45deg) translate(-10px, 50%);
        background-color: $white;
      }
      &.show{
        display: block;
      }
      ul{
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
      }
      li{
        border-bottom: 1px solid #DADBDD;
        padding: 16px;
        display: flex;
        gap: 12px;
        align-items: center;
        &:last-child{
          border-bottom: 0;
        }
      }
      .icon{
        display: inline-block;
        svg{
          width: 20px;
          height: 20px;
        }
        path{
          fill: #AFAFAF;
        }
      }
      .item{
        font-size: 16px;
        line-height: 24px;
      }
    }
    .week-picker__btn{
      color: #289FC1;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-left: 4px;
      cursor: pointer;
      @media screen and (min-width: 360px){
        margin-left: 8px;
      }
    }
    .week-picker__arrow{
      cursor: pointer;
      width: 24px;
      height: 24px;
      text-align: center;
      @media screen and (min-width: 360px){
        margin-right: 8px;
      }
      svg{
        width: 8px;
        height: 12px;
        path{
          fill: rgba(0, 0, 0, 0.6);
        }
      }
    }
    .week-picker__selector{
      margin-left: 0px;
      cursor: pointer;
    }
    .react-datepicker{
      .react-datepicker__day{
        border-radius: 0.3rem;
        &--selected{
          color: #185abc;
          background-color: #d2e3fc;
        }
        &--today{
          background-color: $blue;
          color: $white;
        }
      }
    }
    
  }
}
// .MiniCalendarAddEvent{
//   border-radius: 8px;
//   :global {
//       .event-modal{
//       padding: 10px 0px 16px;
//       }
//       .header{
//         h2{
//           font-size: 17px!important;
//           line-height: 16px;
//         }
//       }
//     .event-modal__input{
//       margin-top: 20px;
//       label{
//         display: none;
//       }
//       input {
//         color: $text-black;
//         font-family: $primary-font;
//         font-style: normal;
//         font-weight: normal;
//         font-size: 16px;
//         line-height: 20px;
//         padding: 14px 12px;
//         border: 1px solid $light-grey;
//         border-radius: 3px;
//         width: 100%;
//         &:active,
//         &:focus {
//           box-shadow: none;
//         }
//       }
//     }
//     .event-modal__date{
//       margin-bottom: 20px;
//     }
//     .event-modal__time{
//       display: flex;
//       gap: 8px;
//       align-items: center;
//       margin-bottom: 21px;
//       .time-start {
//         flex-basis: calc(50% - 12px);
//         max-width: calc(50% - 12px);
//       }
//       .time-end {
//         flex-basis: calc(50% - 12px);
//         max-width: calc(50% - 12px);
//       }
//     }
//     .event-modal__btn{
//       display: flex;
//       justify-content: flex-end;
//       gap: 12px;
//       .btn {
//         padding: 10px 16px;
//         font-size: 16px;
//         max-height: 40px;
//         &--white {
//           color: $black;
//           border: none;
//           background-color: #eeeeee;
//           border-radius: 3px;
//         }
//       }
//     }
//     }
//   }
