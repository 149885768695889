@use "styles/shared" as *;

.Chart {
  :global {
    .chart-tooltip{
      padding: 14px 16px 16px;
      background: rgba(255, 255, 255, 0.9);
      border: 1px solid $light-grey;
      border-radius: 3px;
      min-width: 196px;
      h3{
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 12px;
      }
      .tooltip__row{
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        &:last-child{margin-bottom: 0;}
        p{
          line-height: 20px;
          margin-bottom: 0;
          text-transform: capitalize;
          color: $text-grey2;
        }
        span{
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }
        .tooltip__data{
          &--blue{
            color: $blue2;
          }
          &--green{
            color: $green2;
          }
        }
      }
    }
    .chart-dot{
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}
