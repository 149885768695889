@use "styles/shared" as *;

.Notifications {
  height: 100%;
  :global {
    .notification__header {
      border-bottom: 1px solid $light-grey;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        margin-bottom: 0;
      }
    }
    .notification__title{
      display: flex;
      align-items: center;
      gap: 14px;
      svg{
        width: 24px;
        height: 24px;
        padding: 5px;
        cursor: pointer;
      }
    }
    .notification__btn {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #3e98c7;
      cursor: pointer;
      &:hover {
        color: $hover-blue;
      }
    }
    .notification__tray {
      height: calc(100% - 65px);
    }
    .notification__group {
      border-bottom: 1px solid #f0f3f4;
      padding: 12px 12px 16px;
      display: flex;
      gap: 10px;
      justify-content: space-between;
    }
    .notification__thumb {
      min-width: 32px;
      height: 32px;
      background-color: #eeeeee;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 20px;
      }
    }
    .notification__content {
      min-height: 109px;
      max-height: 536px;
      max-width: 400px;
      overflow-y: auto;
    }
    .notification__details {
      width: 100%;
      p {
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 8px;
        line-height: 20px;
      }
      span {
        color: rgba(0, 0, 0, 0.38);
        font-size: 16px;
        line-height: 20px;
      }
    }
    .notification__head {
      margin-bottom: 8px;
      h3 {
        font-weight: 600;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 2px;
        &:last-child {
          margin-bottom: 8px;
        }
      }
      &.clickable{
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .notification__status {
      padding: 2px;
      width: 16px;
      align-self: center;
      position: relative;
      .checkmark {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        display: block;
        transition: all 0.3s ease-in-out;
        border: none;
        background-color: #389d5d;
        &.marked-as-read {
          background-color: $white;
          border: 1px solid rgba(0, 0, 0, 0.38);
          cursor: pointer;
          span {
            border-color: rgba(0, 0, 0, 0.38);
            background-color: $white;
          }
          &:hover {
            transform: none;
            & + .notification__tooltip {
              visibility: visible;
            }
          }
        }
        &:hover {
          transform: scale(1.2);
          & + .notification__tooltip {
            visibility: visible;
          }
        }
      }
      span {
        display: inline-block;
        position: absolute;
        top: 2px;
        left: 4px;
        width: 5px;
        height: 8px;
        border: solid $white;
        border-width: 0 1.5px 1.5px 0;
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        background-color: #389d5d;
      }
    }
    .notification__tooltip {
      visibility: hidden;
      background-color: $text-black;
      border-radius: 4px;
      position: absolute;
      top: -32px;
      right: -10px;
      display: block;
      transition: all 0.3s ease-in-out;
      p {
        padding: 4px 12px;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
        color: $white;
        white-space: nowrap;
      }
    }
    .no-notifications {
      padding: 12px 16px;
      line-height: 24px;
    }
    .no-more {
      text-align: center;
      padding: 10px 20px;
      font-size: 14px;
    }
  }
}
