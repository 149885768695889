@use "styles/shared" as *;

.PreNavbar {
  background-color: $text-black;
  z-index: 5;
  &:global(.navbar){
    position: fixed;
    width: 100%;
    padding: 6px 0;
    @media screen and (min-width: 480px){
      position: relative;
    }
    @media screen and (min-width: 992px) {
      padding: 8px 0;
    }
    // &--sticky{
    //   position: fixed;
    // }
  }
  // &:global(.navsearch){
  //   .navbar__wrap{
  //     min-height: 44px;
  //     padding: 4px;
  //   }
  // }
  :global {
    .navbar__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 6px 4px;
      position: relative;
      @media screen and (min-width: 992px) {
        padding: 8px 12px;
      }
      &.show{
         .navbar__middle{
           @include device(mobile){
            display: flex;
           }
         }
         .navbar__left{
           @include device(mobile){
            display: none;
           }
         }
      }
    }
    .navbar--minimal{
      @media screen and (min-width: 992px){
        padding: 4px 0;
      }
      @media screen and (max-width: 992px){
        margin: 0 auto;
      }
      .navbar__burger{
        display: none;
      }
    }
    .navbar__left {
      order: 3;
      display: flex;
      align-items: center;
      // display: none;
      @media screen and (min-width: 992px) {
        margin-left: 4px;
        order: 1;
      }
      .btn {
        margin-left: 28px;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .navbar__middle {
       @include device(mobile){
        position: absolute;
        left: 0;
        right:4px;
        background-color: #373c43;
        padding: 4px 0px;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        display: none;
        z-index: 999;
       }
      @media screen and (min-width: 992px){
        display: block;
        order: 2;
        width: 100%;
        max-width: 400px;
      }
    }
    .navbar__search{
      flex-basis: calc(100% - 66px);
       @include device(mobile){
        flex-basis: auto;
        width: 293px;
       }
        @media screen and (max-width: 360px){
          width: 230px;
        }
    }
    .navbar__cta{
      flex-basis: 50px;
      color: $white;
       @include device(mobile){
        font-size: 14px;
       }
      @include device(mobile){
        flex-basis: auto;
        }
      &:hover{
        color: $hover-blue;
      }
    }
    .navbar__logo{
      svg{
        height: 32px;
      }
    }
    .navbar__right{
      display: flex;
      justify-content: space-between;
      align-items: center;
      order: 1;
      // display: none;
      @media screen and (min-width: 992px) {
        order: 3;
      }
    }
    .navbar__search-icon{
      display: block;
      margin-right: 16px;
      cursor: pointer;
      @media screen and (min-width: 992px) {
        display: none;
      }
    }
    .navbar__burger{
      padding: 6px 3px;
      cursor: pointer;
      display: block;
      margin-right: 16px;
      @media screen and (min-width: 992px) {
        display: none;
      }
      &.active{
        span{
          &:nth-child(1){
            transform: rotate(45deg) translate(3px, -4px);
          }
          &:nth-child(2){
            opacity: 0;
          }
          &:nth-child(3){
            transform: rotate(-45deg) translate(2px, 4px);
          }
        }
      }
      span{
        display: block;
        width: 18px;
        height: 2px;
        margin-bottom: 3px;
        background-color: $white;
        transition: all 0.3s ease-in-out;
        &:last-child{
          margin-bottom: 0;
          transform-origin: 0% 100%;
        }
        &:first-child{
          transform-origin: 0% 0%;
        }

      }
    }
    .navbar__btn{
      display: none;
      @media screen and (min-width: 992px) {
        display: block;
      }
    }
    .navbar-mob__search{
      background-color: $text-black;
      padding: 14px 16px;
      width: 100%;
      position: absolute;
      top: -100%;
      left: 0;
      z-index: 1;
      transition: all 0.3s ease-in-out;
      .search-bar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        width: 100%;
        .search-input{
          width: 100%;
          position: relative;
        }
        .close-btn{
          width: 13px;
          position: absolute;
          top: 11px;
          right: 17px;
          cursor: pointer;
        }
        span{
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: $white;
        }
      }
      &.search-show{
        top: 0;
      }
      &.search-hide{
        display: none;
      }
    }
  }
}
