@use "styles/shared" as *;

.TimeInput {
  :global {
    input {
      box-sizing: border-box;
      font-family: $primary-font;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      padding: 20px 16px 7px;
      border: 1px solid $light-grey;
      border-radius: 3px;
      color: $text-black;
      &:-webkit-autofill {
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
      }
    }
    .input-wrap {
      position: relative;
    }
    input:focus + .placeholder,
    input:-webkit-autofill + .placeholder,
    :not(input[value=""]) + .placeholder {
      font-size: 12px;
      line-height: 15px;
      transform: translate(0, -9px);
    }
    .results {
      background: #fff;
      top: 43px;
      position: absolute;
      z-index: 9;
      max-height: 200px;
      overflow-y: scroll;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 28px 3px rgba(0, 0, 0, 0.14);
      ul {
        list-style: none;
        padding-left: 16px;
        margin: 0;
        padding: 0;
        li {
          padding: 10px;
          cursor: pointer;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: $text-black;
          &:hover {
            background: $light-grey;
          }
        }
      }
    }
    .form-errors {
      p {
        font-size: 14px;
        line-height: 20px;
        color: $red;
        margin-bottom: 0;
        margin-top: 4px;
      }
    }
  }
}

// .TimeInput__default {
//   :global {
//   }
// }