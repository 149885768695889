@use './shared' as *;

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $primary-font;
  line-height: normal;

  &.ReactModal__Body--open{
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: $primary-font;
  font-style: normal;
  color: $text-black;
}
h1,
.title {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
}
h2 {
  font-weight: 700;
  font-size: 23px;
  line-height: 36px;
  &.sm {
    font-size: 18px;
    line-height: 20px;
    @media screen and (min-width: 360px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &.lg {
    font-size: 20px;
    line-height: 28px;
    @media screen and (min-width: 360px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
}
h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  &.lg {
    font-size: 17px;
    line-height: 16px;
  }
}
p {
  font-weight: normal;
  font-size: 16px;
}
a,
label,
span,
input,
ul,
ol,
em {
  font-family: $primary-font;
  font-style: normal;
  font-weight: normal;
}
a {
  &:hover {
    color: $text-black;
  }
}
strong,
b {
  font-family: $primary-font;
  font-style: normal;
}
h2 {
  span {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    line-height: 24px;
    margin-left: 4px;
  }
}
textarea {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  &:focus {
    outline: 1px solid transparent;
  }
}
ul,
ol {
  li {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 400;
  }
}
.container-fluid--lg {
  padding: 0;
  max-width: 100%;
  @media screen and (min-width: 480px) {
    padding: 0 20px;
  }
  @media screen and (min-width: 768px) {
    padding: 0 40px;
  }
  @media screen and (min-width: 1199px) {
    padding: 0 72px;
  }
}
.in-desktop {
  display: none;
  @media screen and (min-width: 992px) {
    display: block;
  }
}
.in-desktop-md {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}
.in-desktop-sm {
  display: none;
  @media screen and (min-width: 480px) {
    display: block;
  }
}
.in-mob {
  display: block;
  @media screen and (min-width: 992px) {
    display: none;
  }
}
.in-mob-md {
  display: block;
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.in-mob-sm {
  display: block;
  @media screen and (min-width: 480px) {
    display: none;
  }
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  transition: all 0.5s ease-in-out;
  background-color: #b5b8b9;
  border-radius: 10px;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  transition: all 0.5s ease-in-out;
  background-color: #e8e8e8;
  border-radius: 5px;
  cursor: pointer;
}

