@use "styles/shared" as *;
.AdminNav {
  &:global(.active) {
    display: block;
    :global {
      .overlay {
        @media screen and (max-width: 992px) {
          display: block;
        }
      }
      .nav__wrap {
        @media screen and (max-width: 992px) {
          left: 0px;
        }
      }
    }
  }
  :global {
    .overlay {
      background-color: rgba(0, 0, 0, 0.2);
      height: calc(100% + 56px);
      width: 100%;
      position: fixed;
      top: -56px;
      z-index: 9;
      display: none;
    }
    .nav__wrap {
      transition: all 0.2s ease-in-out;
      @media screen and (max-width: 992px) {
        background-color: $text-black;
        height: 100vh;
        max-height: 100vh;
        position: fixed;
        top: 0;
        left: -370px;
        width: 90%;
        max-width: 370px;
        z-index: 99;
        padding: 12px 24px 32px;
      }
    }
    .nav__logo {
      display: block;
      margin-bottom: 17px;
      @media screen and (min-width: 992px) {
        display: none;
      }
    }
    .nav {
      background-color: $text-black;
      // display: none;
      @media screen and (min-width: 992px) {
        border-bottom: 1px solid #d0d3d4;
        background-color: #fef7d9;
        display: block;
      }
    }
    .nav__list {
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;
      @media screen and (min-width: 992px) {
        display: flex;
        margin: 0 24px;
      }
      .nav__item {
        @media screen and (min-width: 992px) {
          padding: 0 24px;
        }
        &:last-child {
          padding: 0;
          .nav__link {
            &::after {
              content: "";
              height: 0;
            }
          }
        }
      }
    }
    .nav__link {
      text-decoration: none;
      font-family: $primary-font;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 50px;
      color: $white;
      position: relative;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      @media screen and (min-width: 992px) {
        color: $text-black;
      }
      &.active {
        font-weight: 700;
      }
      &::after {
        position: absolute;
        bottom: -15px;
        left: -24px;
        right: 0;
        width: 0;
        margin: auto;
        content: "";
        height: 2px;
        border-radius: 10px;
        background-color: $blue;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        @media screen and (min-width: 992px) {
          color: $text-grey;
          &::after {
            width: calc(100% + 48px);
          }
        }
      }
    }
    .nav__btn {
      margin-top: 17px;
    }
    .nav__user {
      cursor: pointer;
      @media screen and (max-width: 992px) {
        display: none;
      }
      &:hover {
        .nav__link {
          color: $text-grey;
        }
      }
      a {
        margin-right: 4px;
      }
    }
    .nav__popper {
      z-index: 20;
    }
    .nav__tray {
      background: $text-black;
      position: absolute;
      bottom: 32px;
      @media screen and (min-width: 992px) {
        position: relative;
        min-width: 240px;
        max-width: 240px;
        border-radius: 2px;
        box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.2);
        background: $white;
      }
      .tray__content {
        @media screen and (min-width: 992px) {
          padding: 16px 16px 14px;
        }
        h3 {
          cursor: context-menu;
          font-weight: 600;
          line-height: 20px;
          margin-bottom: 2px;
          max-width: 216px;
          overflow-x: hidden;
          text-overflow: ellipsis;
          color: $white;
          @media screen and (min-width: 992px) {
            color: $text-black;
          }
        }
        p {
          cursor: context-menu;
          line-height: 24px;
          margin-bottom: 0;
          max-width: 216px;
          overflow-x: hidden;
          text-overflow: ellipsis;
          color: $white;
          @media screen and (min-width: 992px) {
            color: $text-black;
          }
        }
      }
      .tray__cta {
        width: 100%;
        display: inline-block;
        text-decoration: none;
        font-size: 16px;
        line-height: 24px;
        color: $white;
        cursor: pointer;
        margin-top: 24px;
        @media screen and (min-width: 992px) {
          border-top: 1px solid #dadbdd;
          margin-top: 0;
          padding: 10px 16px 16px;
          color: $text-black;
        }
        &:hover {
          font-weight: 600;
        }
      }
      &::before {
        content: "";
        width: 19px;
        height: 19px;
        background-color: $white;
        transform: rotate(-45deg);
        border-radius: 0px 2px;
        position: absolute;
        right: 8px;
        top: -9px;
        display: none;
        @media screen and (min-width: 992px) {
          display: inline-block;
        }
      }
    }
    // .nav-mob {
    //   background-color: $white;
    //   height: calc(100vh - 64px);
    //   width: 100%;
    //   position: absolute;
    //   bottom: 0;
    //   z-index: 10;
    //   transition: all 0.3s ease-in-out;
    //   &.show {
    //     left: 0;
    //   }
    //   &.hide {
    //     left: -100%;
    //   }
    //   .nav-mob__list {
    //     list-style: none;
    //     padding: 32px 24px 24px;
    //     margin-bottom: 0;
    //     li {
    //       margin-bottom: 32px;
    //       .nav-mob__link {
    //         width: fit-content;
    //         text-decoration: none;
    //         color: $text-black;
    //         font-size: 16px;
    //         line-height: 20px;
    //         display: block;
    //         transition: all 0.3s ease-in-out;
    //         &.active {
    //           font-weight: 700;
    //         }
    //         &:hover {
    //           padding-left: 4px;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}
