@use "styles/shared" as *;

.Button {
  font-family: $primary-font;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  background-color: $blue;
  color: $white;
  padding: 8px 16px;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  &:hover {
    background-color: $hover-blue;
    color: $white;
  }

  /*
  * Overriding the default button styles from Bootstrap
  */
  &:global(.btn) {
    font-family: $primary-font;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    background-color: $blue;
    border: none;
    color: $white;
    padding: 8px 16px;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: $hover-blue;
      color: $white;
    }
    &:focus {
      box-shadow: none;
    }
    &:global(--modal) {
      border-radius: 3px;
      font-size: 16px;
      line-height: 14px;
      padding: 16px 16px 15px;
      width: 100%;
    }
    &:global(--white) {
      background-color: $white;
      color: $blue;
      border: 1px solid $blue;
      &:hover {
        color: $hover-blue;
        background-color: $white;
        box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        border-color: $hover-blue;
      }
    }
    &:global(--md) {
      padding: 13px;
      font-size: 16px;
      width: 100%;
      border-radius: 3px;
      max-height: 46px;
    }
    &:global(--sm) {
      padding: 12px;
      font-size: 16px;
      max-height: 44px;
      border-radius: 3px;
      width: 100%;
    }
  }
  :global {
    .btn-loader {
      svg {
        max-height: 20px;
      }
    }
  }
}
