@use "styles/shared" as *;
.Search {
  :global {
    .results {
      background-color: $white;
      overflow-y: auto;
      width: 100%;
      max-width: 400px;
      min-height: 200px;
      max-height: 767px;
      border-radius: 10px;
      position: relative;
      z-index: 2;
      padding: 21px 0 12px;
      border: 1px solid $light-grey;
      &::-webkit-scrollbar-thumb {
        transition: all 0.5s ease-in-out;
        background-color: transparent;
      }
      &::-webkit-scrollbar-track {
        transition: all 0.5s ease-in-out;
        background-color: transparent;
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: #b5b8b9;
        }
        &::-webkit-scrollbar-track {
          background-color: #e8e8e8;
        }
      }
      @include device(mobile){
        max-width: 292px;
        margin-top: 23px;
      }
    }
    .results__list {
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      ::-webkit-scrollbar-track {
        border-radius: 18px;
      }
      &:last-child {
        border-bottom: 0;
      }
      h3 {
        font-size: 16px;
        line-height: 20px;
        color: #289fc1;
        font-weight: 600;
        padding: 20px 16px 8px;
        margin-bottom: 0;
      }
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 12px;
      }
      li {
        padding: 8px 16px;
        &:hover {
          background: #eeeeee;
        }
        a {
          text-decoration: none;
          color: rgba(55, 60, 67, 0.38);
          span {
            color: rgba(55, 60, 67, 0.38);
            font-size: 16px;
            line-height: 20px;
            b {
              color: $text-black;
            }
          }
        }
      }
      .title {
        span {
          text-transform: capitalize;
          color: rgba(55, 60, 67, 0.6);
          font-weight: 600;
          b {
            font-weight: 600;
          }
        }
      }
    }
    .results__group {
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 4px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .status {
        color: rgba(55, 60, 67, 0.38);
      }
    }
    .search-bar {
      position: relative;
      z-index: 5;
    }
    .no-results {
      padding: 16px;
    }
  }
}
