.SectionLoader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  &:global{
    &.loader{
      margin: 80px auto;
    }
  }
}
