@use "styles/shared" as *;

.DashboardSales {
  background-color: $white;
  padding: 16px;
  min-height: 220px;
  height: 100%;
  :global {
    .sales__header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      h2{
        margin-bottom: 0;
        margin-right: 8px;
      }
    }
    .sales__sort{
      display: flex;
      gap: 12px;
    }
    .sales__content{
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }
    .sales__card{
      flex-basis: 100%;
      @media screen and (min-width: 768px) {
        flex-basis: calc(50% - 6px);
      }
    }
    .dashboard-select{
      @include device(mobile){
        min-width: 104px;
      }
        @media screen and (max-width: 360px) {
         min-width: 95px;
      }
    }
  }
}
