@use "styles/shared" as *;

.DashboardNotification {
  border-radius: 3px;
  background-color: $white;
  @media screen and (min-width: 991px){
    height: calc(100vh - 149px);
  }
  :global {
    .notification__content{
      max-height: calc(100vh - 214px);
      max-width: 100%;
    }
    .infinite-scroll-component{
      max-height: calc(100vh - 214px);
      max-width: 100%;
    }
    .notification__btn{
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #3E98C7;
      cursor: pointer;
      &:hover{
        color: $hover-blue;
      }
    }
    .notification__group{
      border-bottom: 1px solid #F0F3F4;
      padding: 12px 12px 16px;
      display: flex;
      gap: 10px;
      justify-content: space-between;
    }
    .notification__thumb{
      min-width: 32px;
      height: 32px;
      background-color: #EEEEEE;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg{
        width: 20px;
      }
    }
    .notification__details{
      width: 100%;
      p{
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 8px;
        line-height: 20px;
      }
      span{
        color: rgba(0, 0, 0, 0.38);
        font-size: 16px;
        line-height: 20px;
      }
    }
    .notification__head{
      margin-bottom: 8px;
      h3{
        font-weight: 600;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 2px;
        &:last-child{
          margin-bottom: 8px;
        }
      }
    }
    .dboard__notification{
          @include device(mobile){
          display: none;
          }
        .notification__header{
             @media screen and (max-width: 360px){
          padding: 12px;
         }
        }
    }
  }
}

