@use "styles/shared" as *;
.Modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  outline: none;
  min-height: 200px;
  padding: 16px;
  padding-bottom: 0;
  margin: 40px auto;
  max-width: 560px;
  width: 91%;

  @media screen and (min-width: 540px) {
    width: 90%;
    padding: 32px 40px 24px;
  }

  &:global(.size-sm) {
    max-width: 480px;
  }
  &:global(.size-sm) {
    max-width: 240px;
  }
  :global {
    .form-control {
      cursor: pointer;
    }
    .header {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      h2 {
        margin-bottom: 0px;
        font-size: 24px;
        font-weight: 600;
      }
      svg.close {
        cursor: pointer;
      }
    }
    .warning_message{
      margin-top: 20px;
      margin-bottom: 68px;
    }
    .btn-container {
      display: flex;
      justify-content: end;
      .cancel {
        margin-right: 16px;
      }
    }
  }
}

.change_job_status {
  width: 560px;
  height: 292px;
}

.ModalOverlay {
  background-color: rgba($color: #000000, $alpha: 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: auto;
}