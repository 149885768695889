@use "styles/shared" as *;
.Loader {
  width: 100%;
  height: 100vh;
  background-color: $bg-yellow;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include device(mobile){
    max-height: -webkit-fill-available;
  }
  :global {
    .loader__icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .nvam-logo {
      position: absolute;
      left: 50%;
      bottom: 48px;
      transform: translateX(-50%);
    }
  }
}
