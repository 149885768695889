$text-black: #373c43;
$text-black2: #2a2e33;
$light-yellow: #fef7d9;
$white: #fff;
$white2: #f5f5f5;
$blue: #248eb4;
$blue2: #3e98c7;
$red: #a94442;
$green: #2b9048;
$green2: #389d5d;
$light-red: #ed818e;
$black: #000;
$bg-yellow: #f6f4ee;
$text-grey: #9c9c9c;
$text-grey2: #8b8b8b;
$light-grey: #d0d3d4;
$hover-blue: #197394;
$dark-black: #2a2e33;
$hover-white: #f5f5f5;
$dark-red: #ad1457;
$light-grey2: #dadce0;
$red-orange: #ea4335;
$yellow: #ffff76;
$hover-grey: #eeeeee;