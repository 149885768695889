@use "styles/shared" as *;

.NavNotifications {
  position: relative;
  display: inline-block;
  width: 27px;
  padding: 2px 5px 2px 4px;
  :global {
    .notification__bell {
      position: relative;
      cursor: pointer;
      svg {
        width: 16px;
        height: 20px;
      }
      .count {
        position: absolute;
        right: -10px;
        top: -5px;
        width: 20px;
        height: 20px;
        background-color: $light-red;
        border-radius: 50px;
        font-size: 11px;
        text-align: center;
        color: $white;
        line-height: 20px;
      }
    }
    .infinite-scroll-component {
      max-height: 536px;
      max-width: 100%;
      @media screen and (max-width: 479px){
        max-height: none;
      }
    }
    .notification__popup {
      background-color: #fff;
      z-index: 9;
      min-width: 100vw;
      max-width: 100%;
      @media screen and (min-width: 480px){
        min-width: 400px;
        border-radius: 3px;
        border: 1px solid $light-grey;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
      }
      @include device(mobile){
        margin-top:7px ;
      }
    }
  }
}
