@use "styles/shared" as *;

.SalesCard {
  background-color: #F9F9F9;
  padding: 16px 16px 20px;
  :global {
    h3{
      line-height: 20px;
      font-weight: 400;
      color: $text-grey2;
      margin-bottom: 20px;
      @media screen and (min-width:768px) {
        line-height: 25px;
      }
    }
    strong{
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $green2;
      margin-right: 8px;
    }
    .sales__number{
      margin-bottom: 21px;
      display: inline-block;
      b{
        font-weight: 700;
        font-size: 24px;
        line-height: 25px;
        color: $blue2;
      }
    }
    .sales__trend{
      p{
        display: inline-block;
        font-size: 14px;
        line-height: 20px;
        color: $text-grey2;
        margin-bottom: 0;
      }
    }
    .below__zero{
      color: #A92020;
    }
    .sales__direction{
      width: 13px;
      height: 16px;
      display: inline-block;
      svg{
        margin-right: 4px;
        margin-top: -4px;
      }
    }
  }
}
