@use "styles/shared" as *;
.SelectInput {
  position: relative;
  :global {
    select {
      box-sizing: border-box;
      font-family: $primary-font;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      padding: 20px 16px 7px;
      border: 1px solid $light-grey;
      border-radius: 3px;
      color: $text-black;
      background-repeat: no-repeat;
      background-position-x: calc(100% - 16px);
      background-position-y: 21px;
      option {
        color: $text-black;
      }
    }
    .form-control {
      &:focus {
        box-shadow: none;
        border-color: $light-grey;
      }
    }
  }
}
.SelectInput__default {
  :global {
    .chevron-down {
      width: 12px;
      height: 7px;
      position: absolute;
      top: 22px;
      right: 16px;
      pointer-events: none;
    }
    .input-wrap {
      position: relative;
    }
    select {
      &:-webkit-autofill {
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
      }
    }
    label.placeholder {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 16px;
      right: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
      color: $text-grey;
      opacity: 1;
    }
    select[data-selected="false"] {
      color: transparent;
    }
    select:focus + .placeholder,
    select:-webkit-autofill + .placeholder,
    :not(select[data-selected="false"]) + .placeholder {
      font-size: 12px;
      line-height: 15px;
      transform: translate(0, -9px);
    }
    .form-errors {
      p {
        font-size: 14px;
        line-height: 20px;
        color: $red;
        margin-bottom: 0;
        margin-top: 4px;
      }
    }
  }
}

.SelectInput__standard {
  cursor: pointer;
  max-width: 104px;
  @media screen and (min-width: 480px) {
    max-width: 140px;
  }
  @media screen and (min-width: 991px) {
    max-width: 160px;
    min-width: 160px;
  }
  :global {
    select {
      padding: 6px 8px;
      cursor: pointer;
      text-overflow: ellipsis;
      padding-right: 28px;
      background-position-x: calc(100% - 8px);
      background-position-y: 13px;
    }
    .chevron-down {
      width: 12px;
      height: 7px;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      position: absolute;
      pointer-events: none;
      path {
        fill: $text-grey;
      }
    }
    .placeholder {
      display: none;
    }
  }
}

// .SelectInput__standard, .change_job_status_input{
//   min-width: 276px;
// }

.SelectInput__withoutLabel {
  :global {
    select {
      cursor: pointer;
      text-overflow: ellipsis;
      background-position-x: calc(100% - 16px);
      padding: 10px 12px;
      background-position-y: 16px;
    }
    .placeholder {
      display: none;
    }
  }
}
